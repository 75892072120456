<template>
  <b-overlay :show="showOverlay">
    <validation-observer ref="createGameForm" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Game Details</h2>

        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                 label="Name"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.name"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label="Short Description"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.shortDescription"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col v-if="platforms" md="4">
              <b-form-group
                  label="Platforms"

              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="data.platforms"
                      :class="{'border-danger rounded':errors.length > 0 ? true:null}"
                      :options="platforms"
                      :reduce="name => name.id"
                      dir="ltr"
                     label="name"
                      multiple
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Main Picture"
              >
                <MediaHandler ref="mediaHandler" @sendData="setFiles"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Slider Item Picture"
              >
                <MediaHandler ref="mediaHandlerSliderItem" @sendData="setFilesSliderItem"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Slider Picture"
              >
                <MediaHandler ref="mediaHandlerSlider" @sendData="setFilesSlider"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >
                <ckEditorMain ref="editorContent" :defaultContent="data.fullDescription"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>
              </b-form-group>

            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <b-card>
        <b-row>
          <b-col v-if="data" cols="12" md="12">
            <h2>SEO</h2>
          </b-col>
          <b-col class="" md="4" cols="12">
            <b-form-group
                label="Seo Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="data.seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" md="4" cols="12">
            <b-form-group
                label="Seo Url"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="allCharactersToDash"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12">
            <b-form-group
                label="Seo Description"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >

                <b-form-textarea
                    id="data-edit-seoTitle"
                    v-model="data.seoDescription"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pl-1">
            <div class="col-md-12 ">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ data.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 dir="ltr" class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>https://skycoach.com/<span class="serp-arrow"></span></span>
                    <span style="overflow-wrap: anywhere">
                      {{ data.seourl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ data.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ data.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ data.seourl.replaceAll(' ', '-') }}/</small>
                  <small>
                    https://skycoach.com/
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ data.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
                @click="createGame"
            >
              <span class="mr-25 align-middle">Submit</span>

            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetAllPlatforms } from '@/libs/Api/platforms'
import { CreateGame } from '@/libs/Api/games'

export default {
  components: {
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'create-game',
  title: 'create game',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      platforms: null,
      data: {
        platforms: [],
        name: '',
        fullDescription: '',
        shortDescription: '',
        createGamePlatforms: [],
        imageFile: null,
        SliderImageFile:null,
        SliderItemImageFile:null,
        seourl: '',
        seoTitle: '',
        seoDescription: '',
      },
    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.data.seourl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.data.seourl = newValue
          // Remove all characters that are NOT number
          this.data.seourl = this.data.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.data.seourl = this.data.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.data.seourl === '') {
          this.data.seourl = null
        }
      },
    },
  },
  async created() {
    await this.getPlatforms()
  },
  methods: {
    setFilesSliderItem(data){
      if (data) {
        this.data.SliderItemImageFile = data
      }
    },
    setFilesSlider(data){
      if (data) {
        this.data.SliderImageFile = data
      }
    },
    setFiles(data) {
      if (data) {
        this.data.imageFile = data
      }
    },
    async getPlatforms() {
      let _this = this
      _this.showOverlay = true
      let getAllPlatforms = new GetAllPlatforms(_this)
      let data = {
        pageNumber: 1,
        count: 999,
      }
      getAllPlatforms.setParams(data)
      await getAllPlatforms.fetch(function (content) {

        _this.platforms = content.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setEditorContent(content) {
      this.data.fullDescription = content
    },
    async createGame() {
      let _this = this
      Helper.validateForm(_this, 'createGameForm', async () => {

        _this.showOverlay = true
        _this.$refs.mediaHandler.sendFiles()
        _this.$refs.mediaHandlerSlider.sendFiles()
        _this.$refs.mediaHandlerSliderItem.sendFiles()
        let myForm = await Helper.makeFormData(this.data)
        let createGame = new CreateGame(_this)

        createGame.setParams(myForm)
        await createGame.sendFormData(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push('/apps/games/manage-games')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
